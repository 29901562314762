import { faBookmark, faTags } from "@fortawesome/pro-light-svg-icons"
import { faTimes, faTimesSquare } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback } from "react"
import { useFilters } from "../../hooks/use-filters"
import { useStyle } from "../../hooks/use-style"
import { useUser } from "../../hooks/use-user"
import { darkTheme, lightTheme } from "../../services/theme"
import HtmlTooltip from "../widgets/html-tooltip"
import Button from "../layout/button"
import { useModal } from "../../hooks/use-modal"
import AddModerator from "../channel/add-moderator"

export type Props = {
  onAdd: (account: string, title: string, description: string) => void
}

const ModeratorsSidemenu: FunctionComponent<Props> = ({ onAdd }) => {
  const { setContent } = useModal()

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
  }))

  const showAddDialog = useCallback(() => {
    setContent(
      <AddModerator
        onAdd={(account: string, title: string, description: string) => {
          console.log("side menu " + account)
          onAdd(account, title, description)
          setContent(null)
        }}
      />
    )
  }, [onAdd])

  return (
    <>
      <div className="accordion">
        <Button onClick={() => showAddDialog()}>Add moderator</Button>
        <style jsx>{`
          .highlighted {
            font-size: 20px;
            text-align: center;
            /*
              color: #39454a;
              border-radius: 50%;
              width: 22px;
              height: 22px;
              background-color: rgb(255, 213, 79);
              line-height: 24px;
              margin: auto;
              */
          }
          .highlighted:hover {
            cursor: pointer;
          }

          header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1em;
            font-family: Roboto;
          }
          header > :global(:not(:first-child)) {
            margin-left: 1em;
          }
          h1 {
            margin: 0;
            padding: 0;
            font-size: inherit;
            font-style: inherit;
            font-weight: normal;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-weight: lighter;
          }
          h1 > *:not(:first-child) {
            margin-left: 1em;
          }

          .accordion {
            background-color: ${css.backgroundColor};
            border: 1px solid ${css.borderColor};
            transition: 200ms background-color, 200ms border-color;
            border-radius: 0.3em;
            font-size: 0.9em;
            user-select: none;
            padding: 12px;
            /* margin: auto; */
            text-align: center;
          }
          section:not(:first-child) {
            border-top: 1px solid ${css.borderColor};
            transition: 200ms border-color;
          }
          .content {
            padding: 5px;
            border-top: 1px solid ${css.borderColor};
            flex-wrap: wrap;
            display: inline-flex;
          }
          .tag {
            background-color: rgb(105, 105, 105, 0.1);
            padding: 5px;
            margin-right: 5px;
            margin-bottom: 5px;
            border-radius: 5px;
            font-size: 14px;
            word-break: keep-all;
            user-select: none;
          }
          .tag:hover {
            background-color: rgb(105, 105, 105, 0.3);
            cursor: pointer;
          }
          .filterConrols {
            flex-wrap: wrap;
            display: inline-flex;
            opacity: 0.7;
          }
          .filterAction {
            font-size: 20px;
          }
          .filterAction:hover {
            cursor: pointer;
          }
        `}</style>
      </div>
    </>
  )
}

export default ModeratorsSidemenu
