import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { Helmet as Head } from "react-helmet"
import ModeratorPreview from "../components/channel/moderator-preview"
import ModeratorsSidemenu from "../components/layout/moderators-sidemenu"
import { currencies } from "../etc/currencies"
import { exactDate } from "../services/date"
import { Moderator } from "../types"
import { useUser, getCachedSecret } from "../hooks/use-user"
import { rpc } from "../services/node"
import { showToast } from "../components/status-notification"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import ThreeColumnLayout from "../components/layout/three-column-layout"

const ModeratorsPage: FunctionComponent = () => {
  const { user, updateBalance } = useUser()
  const [moderators, setModerators] = useState([])

  const fetchModerators = useCallback(async () => {
    const response = await Promise.resolve(
      fetch("/api/v2/moderators")
        .then(res => res.json())
        .catch(error => {
          console.log("json parsing error occured ", error)
          return null
        })
    )
    if (response) {
      let r = response.hits.hits
      let items = []
      for (var i = 0; i < r.length; i++) {
        let m = r[i]["_source"]
        m.id = r[i]["_id"]
        items.push(m)
      }
      setModerators(items)
    } else {
      setModerators([])
    }
  }, [])

  useEffect(() => {
    fetchModerators()
  }, [])

  const editModerator = useCallback(
    payload => {
      console.log("rpc " + user)
      if (user) {
        console.log("rpc " + payload)
        rpc(
          "edit_moderator",
          JSON.stringify(payload),
          user,
          getCachedSecret(),
          function (res) {
            let amount = 1
            let message
            if (res.status == "ok") {
              message = "Edit supplied"
              console.log(res.data.balance)
              //onAdd(account, title, description)
              if (user) updateBalance(-0.01)
            } else message = "Edit failed"
            showToast(faInfoCircle, "Smartlike network", message)
          }
        )
      }
    },
    [user]
  )

  const onDelete = useCallback(
    (id: string) => {
      console.log(id)
      editModerator({
        account: id,
        title: "",
        description: "",
        group: "e4501ca8-9a83-d68d-932a-bbad23a30bc5",
        command: "delete",
      })
      setModerators(moderators =>
        moderators.filter(moderator => moderator.id !== id)
      )
    },
    [user, moderators]
  )

  const onAdd = useCallback(
    (account: string, title: string, description: string) => {
      console.log(account)
      editModerator({
        account: account,
        title: title,
        description: description,
        group: "e4501ca8-9a83-d68d-932a-bbad23a30bc5",
        command: "add",
      })

      setModerators(moderators =>
        moderators.concat({
          id: account,
          title: title,
          description: description,
          amount: 0,
          ts: Math.floor(Date.now() / 1000),
        })
      )
    },
    [user, editModerator, moderators]
  )

  return (
    <>
      <Head>
        <title>Smartlike</title>
        <meta name="description" content="Smartlike p2p donations" />
        <meta property="og:title" content="Smartlike" />
        <meta property="og:description" content="Smartlike p2p donations" />
      </Head>

      {user != undefined && user.state >= 20 ? (
        <ThreeColumnLayout>
          {{
            content: (
              <div style={{ fontFamily: "Roboto", marginBottom: "20px" }}>
                <div>
                  {moderators.map(m => (
                    <ModeratorPreview
                      key={m.id}
                      moderator={m}
                      onDelete={onDelete}
                    />
                  ))}
                </div>
              </div>
            ),
            sidebar: <ModeratorsSidemenu onAdd={onAdd} />,
          }}
        </ThreeColumnLayout>
      ) : (
        <ThreeColumnLayout>
          {{
            content: (
              <div style={{ fontFamily: "Roboto", marginBottom: "20px" }}>
                <div>
                  {moderators.map(m => (
                    <ModeratorPreview key={m.id} moderator={m} />
                  ))}
                </div>
              </div>
            ),
            sidebar: <></>,
          }}
        </ThreeColumnLayout>
      )}
      <style jsx>{`
        .header {
          text-align: center;
          font-size: 18px;
          margin-bottom: 10px;
        }

        .ttable {
          display: table-row;
        }
        .trow {
          display: table-row;
        }
        .tcell {
          display: table-cell;
          padding: 2px;
        }
        .rate {
          padding: 2px;
          text-align: right;
          font-family: monospace;
          font-size: 14px;
        }
      `}</style>
    </>
  )
}
export default ModeratorsPage
